import * as React from 'react';
import * as styles from './mkrenovations.module.scss';
import WorkExample from '../../../components/WorkExample';

//Components
import Seo from '../../../components/seo';

//Images
import mkRenovationsHeaderImage from '../../../images/work-example-headers/mk-renovations.png';

const MKRenovations = ({location})=>{
    return(
        <WorkExample
            pageName={'mkrenovations'}
            cardColor={'#194458'}
            title={'MK Renovations'}
            subtitle={"A website for a small construction company featuring email form submission with firebase cloud functions and node mailer"}
            imageData={mkRenovationsHeaderImage}
            imageAlt={"MK Renovations"}
            links={[
                {text: "Check out the project", url: "https://kolonayrenovations.com", external: true},
                // {text: "View the code on Github", url: "https://github.com/dkolonay/kolonayrenovations"}
            ]}
            location={location}
        >

            <Seo
                title={"MK Renovations"}
                description={"A website for a small construction company featuring email form submission with firebase cloud functions and node mailer"}
            />

            <h2>About the Project</h2>
            <p>MK Renovations is a one-man home renovation company owned 
and operated by my brother, Mike Kolonay. After successfully builidng up
a small clientelle through word-of-mouth facebook advertising, he was 
ready to add some professionalism to his online presence and automate some
of his intake process for new clients.</p>

            <h2>What I did</h2>
            <ul>
                <li>Contact form that accepts images</li>
                <li>Custom, responsive design for 8 page website</li>
                <li>SEO using Gatsby and React Helmet</li>
            </ul>
        </WorkExample>
    )
}

export default MKRenovations;